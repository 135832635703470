//@ts-ignore
//@ts-nocheck
/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Author: bluecato (https://sketchfab.com/bluecato)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/low-poly-house-d6ece88cd99c4a7c865695e0be61b7d1
Title: low poly house
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'
import { URL } from '../utils/constants'

export function MenuHouse(props) {
  const { nodes, materials } = useGLTF(`${URL}low_poly_house.glb`)
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <group position={[-27430.5, -21859.5, -5616.75]}>
          <mesh castShadow receiveShadow geometry={nodes.Object_3.geometry} material={materials.material_10} />
          <mesh castShadow receiveShadow geometry={nodes.Object_4.geometry} material={materials.material_13} />
          <mesh castShadow receiveShadow geometry={nodes.Object_5.geometry} material={materials.material_15} />
          <mesh castShadow receiveShadow geometry={nodes.Object_6.geometry} material={materials.material_18} />
          <lineSegments geometry={nodes.Object_7.geometry} material={materials.material_7} />
          <mesh castShadow receiveShadow geometry={nodes.Object_8.geometry} material={materials.material_8} />
          <mesh castShadow receiveShadow geometry={nodes.Object_9.geometry} material={materials.material_9} />
          <mesh castShadow receiveShadow geometry={nodes.Object_10.geometry} material={materials.material_1} />
          <mesh castShadow receiveShadow geometry={nodes.Object_11.geometry} material={materials.material_11} />
          <mesh castShadow receiveShadow geometry={nodes.Object_12.geometry} material={materials.material_11} />
          <mesh castShadow receiveShadow geometry={nodes.Object_13.geometry} material={materials.material_11} />
          <mesh castShadow receiveShadow geometry={nodes.Object_14.geometry} material={materials.material_16} />
          <mesh castShadow receiveShadow geometry={nodes.Object_15.geometry} material={materials.material_16} />
          <mesh castShadow receiveShadow geometry={nodes.Object_16.geometry} material={materials.material_16} />
          <mesh castShadow receiveShadow geometry={nodes.Object_17.geometry} material={materials.material_16} />
          <mesh castShadow receiveShadow geometry={nodes.Object_18.geometry} material={materials.material_16} />
          <mesh castShadow receiveShadow geometry={nodes.Object_19.geometry} material={materials.material_16} />
          <mesh castShadow receiveShadow geometry={nodes.Object_20.geometry} material={materials.material_16} />
          <mesh castShadow receiveShadow geometry={nodes.Object_21.geometry} material={materials.material_16} />
          <mesh castShadow receiveShadow geometry={nodes.Object_22.geometry} material={materials.material_16} />
          <mesh castShadow receiveShadow geometry={nodes.Object_23.geometry} material={materials.material_16} />
          <mesh castShadow receiveShadow geometry={nodes.Object_24.geometry} material={materials.material_16} />
          <mesh castShadow receiveShadow geometry={nodes.Object_25.geometry} material={materials.material_16} />
          <lineSegments geometry={nodes.Object_26.geometry} material={materials.material_2} />
          <mesh castShadow receiveShadow geometry={nodes.Object_27.geometry} material={materials.material_2} />
          <mesh castShadow receiveShadow geometry={nodes.Object_28.geometry} material={materials.material_2} />
          <mesh castShadow receiveShadow geometry={nodes.Object_29.geometry} material={materials.material_2} />
          <mesh castShadow receiveShadow geometry={nodes.Object_30.geometry} material={materials.material_4} />
          <mesh castShadow receiveShadow geometry={nodes.Object_31.geometry} material={materials.material_4} />
          <mesh castShadow receiveShadow geometry={nodes.Object_32.geometry} material={materials.material_5} />
          <lineSegments geometry={nodes.Object_33.geometry} material={materials.material_6} />
          <mesh castShadow receiveShadow geometry={nodes.Object_34.geometry} material={materials.material_6} />
          <mesh castShadow receiveShadow geometry={nodes.Object_35.geometry} material={materials.material_7} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload(`${URL}low_poly_house.glb`)
