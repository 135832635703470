/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/
/* eslint-disable */
//@ts-ignore
//@ts-nocheck
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { RigidBody } from '@react-three/rapier'
import { URL } from '../utils/constants'

export function WineRed(props) {
  const { nodes, materials } = useGLTF(`${URL}wineRed.glb`)
  return (
    <RigidBody
      position={props.position}
      colliders={'cuboid'}
      type={'fixed'}
      sensor={true}
      canSleep={false}
      enabledRotations={[false, false, false]}
      name={'wine-red'}
      onIntersectionEnter={({ manifold, target, other }) => {
        console.log('Collision at world position ', manifold.solverContactPoint(0))

        if (other.rigidBodyObject) {
          console.log(
            // this rigid body's Object3D
            target.rigidBodyObject.name,
            ' collided with ',
            // the other rigid body's Object3D
            other.rigidBodyObject.name
          )
        }
      }}>
      <group rotation={props.rotation} position={props.objPosition} dispose={null}>
        <mesh castShadow receiveShadow geometry={nodes.Mesh_wineRed.geometry} material={materials.brownDarkest} />
        <mesh castShadow receiveShadow geometry={nodes.Mesh_wineRed_1.geometry} material={materials.brownLight} />
        <mesh castShadow receiveShadow geometry={nodes.Mesh_wineRed_2.geometry} material={materials.brown} />
        <mesh castShadow receiveShadow geometry={nodes.Mesh_wineRed_3.geometry} material={materials.red} />
      </group>
    </RigidBody>
  )
}

useGLTF.preload(`${URL}wineRed.glb`)
