/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/
/* eslint-disable */
//@ts-ignore
//@ts-nocheck
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { URL } from '../utils/constants'

export function SimpleWineRed(props) {
  const { nodes, materials } = useGLTF(`${URL}wineRed.glb`)
  return (
    <group {...props} dispose={null}>
      <mesh castShadow receiveShadow geometry={nodes.Mesh_wineRed.geometry} material={materials.brownDarkest} />
      <mesh castShadow receiveShadow geometry={nodes.Mesh_wineRed_1.geometry} material={materials.brownLight} />
      <mesh castShadow receiveShadow geometry={nodes.Mesh_wineRed_2.geometry} material={materials.brown} />
      <mesh castShadow receiveShadow geometry={nodes.Mesh_wineRed_3.geometry} material={materials.red} />
    </group>
  )
}

useGLTF.preload(`${URL}wineRed.glb`)
