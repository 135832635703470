/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/
/* eslint-disable */
//@ts-ignore
//@ts-nocheck
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { URL } from '../utils/constants'

export function SimpleHotDog(props) {
  const { nodes, materials } = useGLTF(`${URL}hotDog.glb`)
  return (
    <group {...props} dispose={null}>
      <mesh castShadow receiveShadow geometry={nodes.hotDog.geometry} material={materials.brownLight}>
        <group position={[0, 0.14, 0]} rotation={[Math.PI, 0, -Math.PI]}>
          <mesh castShadow receiveShadow geometry={nodes.Mesh_sauce.geometry} material={materials.yellow} />
          <mesh castShadow receiveShadow geometry={nodes.Mesh_sauce_1.geometry} material={materials.red} />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.sausage.geometry}
          material={materials.brown}
          position={[-0.293, 0.092, 0]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.93}
        />
      </mesh>
    </group>
  )
}

useGLTF.preload(`${URL}hotDog.glb`)
