/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Author: Pablo88 (https://sketchfab.com/Pablo88)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/mini-stereo-system-27cf7ed06eb3478e92fc7befbbbc4fbc
Title: Mini stereo system
*/
/* eslint-disable */
//@ts-ignore
//@ts-nocheck
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { URL } from '../utils/constants'

export function HiFi(props) {
  const { nodes, materials } = useGLTF(`${URL}mini_stereo_system.glb`)
  return (
    <group {...props} ref={props.objRef} dispose={null}>
      <group scale={0.01}>
        <group position={[129.882, 154.72, -40.733]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
          <mesh castShadow receiveShadow geometry={nodes.resiver_Material009_0.geometry} material={materials['Material.009']} />
          <mesh castShadow receiveShadow geometry={nodes.resiver_Material014_0.geometry} material={materials['Material.014']} />
          <group rotation={[0, Math.PI / 2, 0]}>
            <mesh castShadow receiveShadow geometry={nodes.tweeter_Material012_0.geometry} material={materials['Material.012']} />
            <mesh castShadow receiveShadow geometry={nodes.tweeter_Material009_0.geometry} material={materials['Material.009']} />
            <mesh castShadow receiveShadow geometry={nodes.tweeter_Material013_0.geometry} material={materials['Material.013']} />
          </group>
          <group rotation={[0, Math.PI / 2, 0]}>
            <mesh castShadow receiveShadow geometry={nodes.speaker_Material012_0.geometry} material={materials['Material.012']} />
            <mesh castShadow receiveShadow geometry={nodes.speaker_Material009_0.geometry} material={materials['Material.009']} />
            <mesh castShadow receiveShadow geometry={nodes.speaker_Material013_0.geometry} material={materials['Material.013']} />
          </group>
          <mesh castShadow receiveShadow geometry={nodes.stereo_Material009_0.geometry} material={materials['Material.009']} />
          <mesh castShadow receiveShadow geometry={nodes.stereo_Material010_0.geometry} material={materials['Material.010']} />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.bass_Material015_0.geometry}
            material={materials['Material.015']}
            rotation={[0, Math.PI / 2, 0]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.volume_Material014_0.geometry}
            material={materials['Material.014']}
            rotation={[0, Math.PI / 2, 0]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.indicator_Material014_0.geometry}
            material={materials['Material.014']}
            rotation={[-0.765, 0, 0]}
            scale={[1, 1.346, 1.234]}
          />
          <mesh castShadow receiveShadow geometry={nodes.display_Material008_0.geometry} material={materials['Material.008']} />
          <mesh castShadow receiveShadow geometry={nodes.display_Material007_0.geometry} material={materials['Material.007']} />
          <mesh castShadow receiveShadow geometry={nodes.display_Material006_0.geometry} material={materials['Material.006']} />
          <mesh castShadow receiveShadow geometry={nodes.display_Material004_0.geometry} material={materials['Material.004']} />
          <mesh castShadow receiveShadow geometry={nodes.display_Material005_0.geometry} material={materials['Material.005']} />
          <mesh castShadow receiveShadow geometry={nodes.display_Material003_0.geometry} material={materials['Material.003']} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload(`${URL}mini_stereo_system.glb`)
