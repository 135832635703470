/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/
/* eslint-disable */
//@ts-ignore
//@ts-nocheck
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { RigidBody } from '@react-three/rapier'
import { URL } from '../utils/constants'

export function SimpleSoda(props) {
  const { nodes, materials } = useGLTF(`${URL}sodaCan.glb`)
  return (
    <group {...props} dispose={null}>
      <mesh castShadow receiveShadow geometry={nodes.Mesh_sodaCan.geometry} material={materials.greyDark} />
      <mesh castShadow receiveShadow geometry={nodes.Mesh_sodaCan_1.geometry} material={materials.greyLight} />
      <mesh castShadow receiveShadow geometry={nodes.Mesh_sodaCan_2.geometry} material={materials.red} />
      <mesh castShadow receiveShadow geometry={nodes.Mesh_sodaCan_3.geometry} material={materials.brownDarkest} />
    </group>
  )
}

useGLTF.preload(`${URL}sodaCan.glb`)
