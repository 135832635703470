/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/
/* eslint-disable */
//@ts-ignore
//@ts-nocheck
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { URL } from '../utils/constants'

export function SimpleBarrel(props) {
  const { nodes, materials } = useGLTF(`${URL}barrel.glb`)
  return (
    <group {...props} dispose={null}>
      <mesh castShadow receiveShadow geometry={nodes.Mesh_barrel.geometry} material={materials.greyLight} />
      <mesh castShadow receiveShadow geometry={nodes.Mesh_barrel_1.geometry} material={materials.brownDark} />
      <mesh castShadow receiveShadow geometry={nodes.Mesh_barrel_2.geometry} material={materials.brown} />
    </group>
  )
}

useGLTF.preload(`${URL}barrel.glb`)
