/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/
/* eslint-disable */
//@ts-ignore
//@ts-nocheck
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { RigidBody } from '@react-three/rapier'
import { URL } from '../utils/constants'

export function Pizza(props) {
  const { nodes, materials } = useGLTF(`${URL}pizza.glb`)
  return (
    <RigidBody
      position={props.position}
      colliders={'cuboid'}
      type={'fixed'}
      sensor={true}
      canSleep={false}
      enabledRotations={[false, false, false]}
      name={'pizza'}
      onIntersectionEnter={({ manifold, target, other }) => {
        console.log('Collision at world position ', manifold.solverContactPoint(0))

        if (other.rigidBodyObject) {
          console.log(
            // this rigid body's Object3D
            target.rigidBodyObject.name,
            ' collided with ',
            // the other rigid body's Object3D
            other.rigidBodyObject.name
          )
        }
      }}>
      <group rotation={props.rotation} position={props.objPosition} dispose={null}>
        <group rotation={[-Math.PI, 0, -Math.PI]} scale={[0.69, 0.869, 0.69]}>
          <mesh castShadow receiveShadow geometry={nodes.Mesh_slice3.geometry} material={materials.brown} />
          <mesh castShadow receiveShadow geometry={nodes.Mesh_slice3_1.geometry} material={materials.yellow} />
          <mesh castShadow receiveShadow geometry={nodes.Mesh_slice3_2.geometry} material={materials.brownDarkest} />
          <mesh castShadow receiveShadow geometry={nodes.Mesh_slice3_3.geometry} material={materials.red} />
          <mesh castShadow receiveShadow geometry={nodes.Mesh_slice3_4.geometry} material={materials.green} />
        </group>
        <group rotation={[-Math.PI, Math.PI / 4, -Math.PI]} scale={[0.69, 0.869, 0.69]}>
          <mesh castShadow receiveShadow geometry={nodes.Mesh_slice3.geometry} material={materials.brown} />
          <mesh castShadow receiveShadow geometry={nodes.Mesh_slice3_1.geometry} material={materials.yellow} />
          <mesh castShadow receiveShadow geometry={nodes.Mesh_slice3_2.geometry} material={materials.brownDarkest} />
          <mesh castShadow receiveShadow geometry={nodes.Mesh_slice3_3.geometry} material={materials.red} />
          <mesh castShadow receiveShadow geometry={nodes.Mesh_slice3_4.geometry} material={materials.green} />
        </group>
        <group rotation={[0, Math.PI / 2, 0]} scale={[0.69, 0.869, 0.69]}>
          <mesh castShadow receiveShadow geometry={nodes.Mesh_slice3.geometry} material={materials.brown} />
          <mesh castShadow receiveShadow geometry={nodes.Mesh_slice3_1.geometry} material={materials.yellow} />
          <mesh castShadow receiveShadow geometry={nodes.Mesh_slice3_2.geometry} material={materials.brownDarkest} />
          <mesh castShadow receiveShadow geometry={nodes.Mesh_slice3_3.geometry} material={materials.red} />
          <mesh castShadow receiveShadow geometry={nodes.Mesh_slice3_4.geometry} material={materials.green} />
        </group>
        <group rotation={[0, Math.PI / 4, 0]} scale={[0.69, 0.869, 0.69]}>
          <mesh castShadow receiveShadow geometry={nodes.Mesh_slice3.geometry} material={materials.brown} />
          <mesh castShadow receiveShadow geometry={nodes.Mesh_slice3_1.geometry} material={materials.yellow} />
          <mesh castShadow receiveShadow geometry={nodes.Mesh_slice3_2.geometry} material={materials.brownDarkest} />
          <mesh castShadow receiveShadow geometry={nodes.Mesh_slice3_3.geometry} material={materials.red} />
          <mesh castShadow receiveShadow geometry={nodes.Mesh_slice3_4.geometry} material={materials.green} />
        </group>
        <group scale={[0.69, 0.869, 0.69]}>
          <mesh castShadow receiveShadow geometry={nodes.Mesh_slice3.geometry} material={materials.brown} />
          <mesh castShadow receiveShadow geometry={nodes.Mesh_slice3_1.geometry} material={materials.yellow} />
          <mesh castShadow receiveShadow geometry={nodes.Mesh_slice3_2.geometry} material={materials.brownDarkest} />
          <mesh castShadow receiveShadow geometry={nodes.Mesh_slice3_3.geometry} material={materials.red} />
          <mesh castShadow receiveShadow geometry={nodes.Mesh_slice3_4.geometry} material={materials.green} />
        </group>
        <group rotation={[0, -Math.PI / 4, 0]} scale={[0.69, 0.869, 0.69]}>
          <mesh castShadow receiveShadow geometry={nodes.Mesh_slice3.geometry} material={materials.brown} />
          <mesh castShadow receiveShadow geometry={nodes.Mesh_slice3_1.geometry} material={materials.yellow} />
          <mesh castShadow receiveShadow geometry={nodes.Mesh_slice3_2.geometry} material={materials.brownDarkest} />
          <mesh castShadow receiveShadow geometry={nodes.Mesh_slice3_3.geometry} material={materials.red} />
          <mesh castShadow receiveShadow geometry={nodes.Mesh_slice3_4.geometry} material={materials.green} />
        </group>
        <group rotation={[0, -Math.PI / 2, 0]} scale={[0.69, 0.869, 0.69]}>
          <mesh castShadow receiveShadow geometry={nodes.Mesh_slice3.geometry} material={materials.brown} />
          <mesh castShadow receiveShadow geometry={nodes.Mesh_slice3_1.geometry} material={materials.yellow} />
          <mesh castShadow receiveShadow geometry={nodes.Mesh_slice3_2.geometry} material={materials.brownDarkest} />
          <mesh castShadow receiveShadow geometry={nodes.Mesh_slice3_3.geometry} material={materials.red} />
          <mesh castShadow receiveShadow geometry={nodes.Mesh_slice3_4.geometry} material={materials.green} />
        </group>
        <group rotation={[Math.PI, -Math.PI / 4, Math.PI]} scale={[0.69, 0.869, 0.69]}>
          <mesh castShadow receiveShadow geometry={nodes.Mesh_slice3.geometry} material={materials.brown} />
          <mesh castShadow receiveShadow geometry={nodes.Mesh_slice3_1.geometry} material={materials.yellow} />
          <mesh castShadow receiveShadow geometry={nodes.Mesh_slice3_2.geometry} material={materials.brownDarkest} />
          <mesh castShadow receiveShadow geometry={nodes.Mesh_slice3_3.geometry} material={materials.red} />
          <mesh castShadow receiveShadow geometry={nodes.Mesh_slice3_4.geometry} material={materials.green} />
        </group>
      </group>
    </RigidBody>
  )
}

useGLTF.preload(`${URL}pizza.glb`)
