//@ts-ignore
//@ts-nocheck
import { useThree } from '@react-three/fiber'
import { useEffect, useRef } from 'react'
import * as THREE from 'three'
import { URL } from './constants'

//
export function Audio(props) {
  const { play, soundRef, type } = props
  const { camera } = useThree()
  const audioLoader = new THREE.AudioLoader()
  useEffect(() => {
    const listener = new THREE.AudioListener()
    camera.add(listener)

    // create a global audio source
    const sound = new THREE.Audio(listener)

    // load a sound and set it as the Audio object's buffer
    audioLoader.load(type === 'click' ? `${URL}sounds/click.ogg` : `${URL}sounds/putdown.mp3`, function (buffer) {
      sound.setBuffer(buffer)

      sound.setVolume(0.7)
      sound.setLoop(false)
      soundRef.current = sound
      if (play) {
        sound.play()
      }
    })
  }, [])

  useEffect(() => {
    if (play && soundRef.current) {
      if (!soundRef.current.isPlaying) {
        soundRef.current?.play()
      }
    }
  }, [play])

  return null
}
